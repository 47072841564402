import './App.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';

import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import { ThemeProvider } from "styled-components";
//import { dark } from "./styles/Themes";


import Navigation from "./components/Navigation";
import About from "./components/sections/About";
import HomePage from "./components/sections/HomePage";
import Roadmap from "./components/sections/Roadmap";
import Team from "./components/sections/Team";
import Footer from "./components/Footer";
import Showcase from "./components/sections/Showcase";
import Faq from "./components/sections/Faq";
import ScrollToTop from "./components/ScrollToTop";


const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});


/*
            <Home
              candyMachineId={candyMachineId}
              connection={connection}
              txTimeout={DEFAULT_TIMEOUT}
              rpcHost={rpcHost}
              network={network}
            /> 
*/


const App = () => {
  return (
    <main>
    <GlobalStyles />
    <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={light}>
          <Navigation />
          <HomePage />
          <About />
          <Roadmap />
          <Showcase />
          <Team />
          <Faq />
          <Footer />
          <ScrollToTop />      
    </ThemeProvider>
    </MuiThemeProvider>
    </main>
  );
};



export default App;
